import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-route-blocker'
import { ProvideAuth, ProvideUserPrefs } from './pages'
import { App } from './pages'
import { LicenseInfo } from '@mui/x-license-pro'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { SnackbarUtilsConfigurator } from './utils/SnackbarUtils'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Clarity from '@microsoft/clarity'

import './i18n'
import { OktaWrapper } from './pages/Login/OktaWrapper'
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from "./pages/Login/msal_config";
import { MsalProvider } from '@azure/msal-react';

LicenseInfo.setLicenseKey(
  'c378779cd1a6183a4c82eb0223772b9dT1JERVI6NDI5MTQsRVhQSVJZPTE2ODMxMDU5MDAwMDAsS0VZVkVSU0lPTj0x',
);

const container = document.getElementById('root')
const root = ReactDOM.createRoot(container)

Clarity.init("kmo39klwy3");

const DismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton onClick={() => closeSnackbar(id)} className="notify-cancel-modal">
      <CloseIcon />
    </IconButton>
  )
}

const msalInstance = new PublicClientApplication(msalConfig);


root.render(
 
  <Suspense fallback={<div>Loading...</div>}>
    <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}  action={key => <DismissAction id={key} />}>
      <SnackbarUtilsConfigurator />

      <BrowserRouter>
        <ProvideAuth>
          <OktaWrapper>
          <MsalProvider instance={msalInstance}>
            <ProvideUserPrefs>
              <App />
            </ProvideUserPrefs>
            </MsalProvider>
          </OktaWrapper>
        </ProvideAuth>
      </BrowserRouter>
    </SnackbarProvider>
  </Suspense> 

)
